import { gsap } from 'gsap'

import Swup from 'swup'

import SwupPreloadPlugin from '@swup/preload-plugin'
import SwupScrollPlugin from '@swup/scroll-plugin'

const swup = new Swup({
	plugins: [
		new SwupPreloadPlugin(),
		new SwupScrollPlugin (
			{ 
				doScrollingRightAway: false,
				animateScroll: {
					betweenPages: false,
					samePageWithHash: true,
					samePage: true
				},
				offset: 260,
				scrollFriction: 0.6,
  				scrollAcceleration: 0.02
			}
		)
	]
});

swup.preloadPages();

function swupActiveLinks(){
	let currentPath = window.location.pathname;
	let links = document.querySelectorAll('#nav a'); // <- put your link selector here
	for (const link of links) {
		let linkPath = (new URL( link.href )).pathname;
		link.ariaCurrent = linkPath == currentPath ? 'page' : false;
	}
}
swup.on('contentReplaced', init, () => {
	swupActiveLinks(); // trigger after swup
});

swupActiveLinks(); // trigger on page load


function init() {

	if (document.querySelector('#pagenav')) {

		const sections = document.querySelectorAll("section[id]");
			
		window.addEventListener("scroll", navHighlighter);

		function navHighlighter() {

		
			let scrollY = window.pageYOffset;
			
			sections.forEach(current => {
				const sectionHeight = current.offsetHeight;
				const sectionTop = current.offsetTop - 260;

				let sectionId = current.getAttribute("id");

				if (
					scrollY > sectionTop &&
					scrollY <= sectionTop + sectionHeight
				){
					document.querySelector(".pagenav a[href*=" + sectionId + "]").classList.add("active");
				} else {
					document.querySelector(".pagenav a[href*=" + sectionId + "]").classList.remove("active");
				}
			});
		}
	}
}

if (document.readyState === 'complete') {
   init();
} else {
    document.addEventListener('DOMContentLoaded', () => init());
}

const root = document.querySelector("#root")
const dayNight = document.querySelector(".dayNight")

dayNight.addEventListener("click", () => {
	root.classList.toggle("other")
})